import React, { Component } from "react";
import JsonData from '../data/data.json';


export class Navigation extends Component {
  state = {
    // Default value
    landingPageData: {
      "Features": "Features",
      "About": "About",
      "Services": "Services",
      "Gallery": "Gallery",
      "Testimonials": "Testimonials",
      "Team": "Team",
      "Contact": "Contact",
    },
  }
  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }
  componentDidMount() {
    this.getlandingPageData();
  }
  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll" href="#page-top" style={{marginTop: "-25px"}}>
              <img src="img/logos/ami10.jpeg" style={{height: "69px"}}></img>
            </a>{" "}
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              {/*<li>*/}
              {/*  <a href="#features" className="page-scroll">*/}
              {/*    Features*/}
              {/*  </a>*/}
              {/*</li>*/}
              <li>
                <a href="#about" className="page-scroll">
                  {this.state.landingPageData.About.value}
                </a>
              </li>
              <li>
                <a href="#services" className="page-scroll">
                  {this.state.landingPageData.Services.value}
                </a>
              </li>
              <li>
                <a href="#portfolio" className="page-scroll">
                  {this.state.landingPageData.Gallery.value}
                </a>
              </li>
              <li>
                <a href="#testimonials" className="page-scroll">
                  {this.state.landingPageData.Testimonials.value}
                </a>
              </li>
              <li>
                <a href="#team" className="page-scroll">
                  {this.state.landingPageData.Team.value}
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll">
                  {this.state.landingPageData.Contact.value}
                </a>
              </li>
              <li>
                <a href="https://amilang.com" className="page-scroll" style={{padding: "2px 2px"}}>
                  <img src="img/languages/en.png"/>
                </a>
              </li>
              <li>
                <a href="https://amilang.org" className="page-scroll" style={{padding: "2px 2px"}}>
                  <img src="img/languages/vi.png"/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
